<template>
  <BCard>
    <div class="text-black text-2xl font-medium mb-2">
      Verifikasi Partner
    </div>
    <BRow>
      <Card
        :icon="'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/profile-2user.svg'"
        :title="'Total Pengajuan'"
        :tooltip="'Total jumlah partner yang dalam status Verifikasi “Request”, “Disetujui”, dan “Ditolak'"
        :total="analytic.total"
        :target="'total-pengajuan'"
      />
      <Card
        :icon="'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/user-search.svg'"
        :title="'Request Verifikasi'"
        :tooltip="'Partner yang mengajukan verifikasi'"
        :total="analytic.total_requested"
        :target="'request-verifikasi'"
      />
      <Card
        :icon="'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/user-tick.svg'"
        :title="'Partner Terverifikasi'"
        :tooltip="'Partner yang proses pengajuannya sudah terverifikasi'"
        :total="analytic.total_approved"
        :target="'partner-terverifikasi'"
      />
      <Card
        :icon="'https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/user-remove.svg'"
        :title="'Verifikasi Ditolak'"
        :tooltip="'Pengajuan verifikasi yang ditolak oleh admin'"
        :total="analytic.total_rejected"
        :target="'verifikasi-ditolak'"
      />
    </BRow>
    <BRow class="items-center mb-1">
      <BCol
        md="3"
        class="text-black text-2xl font-medium mb-1"
      >
        Data Partner
      </BCol>
      <BCol md="9">
        <BRow>
          <BCol md="6" lg="3">
            <BInputGroup class="wrapper-search mb-1">
              <template #prepend>
                <BInputGroupText style="border-right: 0">
                  <span
                    class="k-search-normal-1 font-bold  align-middle mx-[5px]"
                    style="font-size: 20px"
                  />
                </BInputGroupText>
              </template>
              <BFormInput
                v-model="search"
                placeholder="Cari nama atau email"
                style="border-left: 0"
                class="pl-0"
                @input="searchData"
              />
            </BInputGroup>
          </BCol>
          <BCol md="6" lg="3">
            <DateRangePicker
              ref="picker"
              v-model="dateRange"
              :locale-data="locale"
              :ranges="ranges"
              :opens="'left'"
              class="w-100 mb-1"
            >
              <template v-slot:input="picker">
                <div
                  class="d-flex justify-content-between align-items-center w-100"
                >
                  <div class="mr-1">
                    <span
                      v-if="
                        YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(today)
                      "
                      class="text-[#828282]"
                    >
                      Hari ini
                    </span>
                    <span
                      v-else-if="
                        YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last2)
                      "
                      class="text-[#828282]"
                    >
                      2 Hari Terakhir
                    </span>
                    <span
                      v-else-if="
                        YEAR_MONTH_DAY(picker.startDate) === YEAR_MONTH_DAY(last7)
                      "
                      class="text-[#828282]"
                    >
                      7 Hari Terakhir
                    </span>

                    <span
                      v-else
                      class="text-[#828282]"
                    > Bulan ini </span>
                  </div>
                  <BImg src="https://storage.googleapis.com/komerce/assets/icons/calendar.png" />
                </div>
              </template>
            </DateRangePicker>
          </BCol>
          <BCol md="6" lg="3">
            <v-select
              v-model="product"
              label="text"
              :options="optionsOfProduct"
              :reduce="option => option.value"
              :clearable="false"
              class="mb-1"
            >
              <template v-slot:option="option">
                <div
                  class="d-flex"
                >
                  <img
                    v-if="option.value !== ''"
                    class="mr-1 w-6"
                    :src="option.src"
                    :alt="option.value"
                  >
                  {{ option.text }}
                </div>
              </template>
            </v-select>
          </BCol>
          <BCol md="6" lg="3">
            <BFormSelect
              v-model="status"
              :options="statusOptions"
              placeholder="Status"
              class="w-100 mb-1"
            />
          </BCol>
        </BRow>
      </BCol>
    </BRow>
    <BOverlay
      :show="loading"
      spinner-variant="primary"
      variant="light"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        style="height: calc(100vh - 400px); overflow-y: scroll;"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          show-empty
          empty-text="Tidak ada data yang ditampilkan."
          responsive
          class="mt-1"
          hover
          selectable
          select-mode="single"
        >
          <template #cell(requested_date)="data">
            <span class="font-medium">{{ DAY_MONTH_YEAR(data.item.requested_date) }}</span>
            <br>
            <span class="text-sm text-[#828282]">
              {{ TIME(data.item.requested_date) }}
            </span>
          </template>
          <template #cell(partner_name)="data">
            <span class="font-medium">{{ data.item.partner_name }}</span>
            <br>
            <span class="text-sm text-[#828282]">{{ data.item.partner_email }}</span>
          </template>
          <template #cell(list_product)="data">
            <div class="d-flex gap-4 justify-center">
              <div
                v-for="(icon, idx) in data.value"
                :key="idx"
              >
                <img
                  :src="icon"
                >
              </div>
            </div>
          </template>
          <template #cell(file)="data">
            <span
              class="text-primary underline"
              @click="$router.push(`/verification-ktp/${data.item.id}/file/${data.item.partner_id}`)"
            >
              Lihat Berkas
            </span>
          </template>
          <template #cell(history)="data">
            <div
              class="flex flex-row items-center justify-center gap-4"
              @click="$router.push(`/verification-ktp/history/${data.item.id}`)"
            >
              <div class="text-primary underline">
                Lihat Riwayat
              </div>
              <BBadge
                variant="primary"
                style="border-radius: 50px"
              >
                {{ data.item.history_request_count }}
              </BBadge>
            </div>
          </template>
          <template #cell(status)="data">
            <div :class="getClassStatus(data.item.status)">
              {{ getLabelStatus(data.item.status) }}
            </div>
          </template>
        </BTable>
      </div>
    </BOverlay>
    <div class="w-50 my-1">
      <div class="text-sm">
        Note :
      </div>
      <span class="text-danger">*</span>
      <small class="text-xs">Verifikasi hanya untuk kebutuhan Komcards dan Komship. Icon produk lain hanya sebagai informasi bahwa user juga memakai produk tersebut.</small>
    </div>
  </BCard>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import vSelect from 'vue-select'
import {
  LABELDAY, LABELMONTH, YEAR_MONTH_DAY, DAY_MONTH_YEAR, TIME,
} from '@/libs/filterDate'
import {
  firstDateOfMonth, last2, last7, today,
} from '@/store/helpers'
import { newAxiosIns } from '@/libs/axios'
import { alertError, alertSuccess } from '@toast'
import secureLocalStorage from '@/libs/secureLocalstorage'
import {
  chooseStatus, configColumnPartner, getClassStatus, getLabelStatus, iconProduct, optionsProduct,
} from './config'
import Card from './Card.vue'

export default {
  components: { Card, DateRangePicker, vSelect },
  data() {
    return {
      last2,
      last7,
      today,
      firstDateOfMonth,
      chooseStatus,
      statusOptions: chooseStatus,
      status: secureLocalStorage.get('statusVerificationKomcard') ? secureLocalStorage.get('statusVerificationKomcard') : null,
      product: '',
      search: '',
      loading: false,
      analytic: {},
      loadingCard: false,
      offset: 0,
      limit: 25,
      lastData: false,
      items: [],
      fields: configColumnPartner,
      iconOfProduct: iconProduct,
      optionsOfProduct: optionsProduct,
      DAY_MONTH_YEAR,
      TIME,
      YEAR_MONTH_DAY,
      getClassStatus,
      getLabelStatus,
      alertError,
      alertSuccess,
      dateRange: {
        startDate: secureLocalStorage.get('paramDateVerificationKomcardStartDate') ? secureLocalStorage.get('paramDateVerificationKomcardStartDate') : firstDateOfMonth,
        endDate: secureLocalStorage.get('paramDateVerificationKomcardEndDate') ? secureLocalStorage.get('paramDateVerificationKomcardEndDate') : today,
      },
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      ranges: {
        'Hari ini': [today, today],
        '2 Hari terakhir': [last2, today],
        '7 Hari terakhir': [last7, today],
        'Bulan ini ': [firstDateOfMonth, today],
      },
    }
  },
  watch: {
    status: {
      handler(value) {
        secureLocalStorage.set('statusVerificationKomcard', value)
        this.getList()
      },
    },
    product: {
      handler(value) {
        this.getList()
      },
    },
    dateRange: {
      handler(value) {
        secureLocalStorage.set('paramDateVerificationKomcardStartDate', YEAR_MONTH_DAY(value.startDate))
        secureLocalStorage.set('paramDateVerificationKomcardEndDate', YEAR_MONTH_DAY(value.endDate))
        this.getList()
      },
    },
  },
  mounted() {
    this.getList()
    this.getAnalytic()
  },
  created() {
    window.onscroll = () => {
      if ((window.innerHeight + window.scrollY) >= document.getElementById('scroll').offsetHeight && !this.loading) {
        this.getNextData()
      }
    }
  },
  methods: {
    handleScroll() {
      const table = document.getElementById('table')
      if ((table.scrollTop >= table.scrollHeight - table.offsetHeight - 5) && !this.loading && !this.lastData) {
        this.getNextData()
      }
    },
    async getAnalytic() {
      this.loadingCard = true
      const url = '/auth/api/v1/admin/ktp-verification/dashboard'
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.analytic = data
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal load data' })
        })
    },
    async getList() {
      this.loading = true
      this.offset = 0
      const params = {
        start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
        end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
        offset: this.offset,
        limit: this.limit,
        status: this.status,
        search: this.search,
        filter_products: this.product === '' ? [] : this.product,
      }
      const url = '/auth/api/v1/admin/ktp-verification'
      await newAxiosIns.get(url, { params })
        .then(res => {
          const { data } = res.data
          data.forEach(item => {
            // eslint-disable-next-line no-param-reassign
            item.list_product = []

            iconProduct.forEach(type => {
              if (item[type.name]) {
                item.list_product.push(iconProduct.find(p => p.name === type.name).src)
              }
            })
          })
          this.loading = false
          this.offset = data.length
          this.items = data
          if (data.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(() => {
          this.loading = false
          this.$toast_error({ message: 'Gagal load data' })
        })
    },
    getNextData() {
      if (!this.lastData) {
        this.loading = true
        const params = {
          start_date: YEAR_MONTH_DAY(this.dateRange.startDate),
          end_date: YEAR_MONTH_DAY(this.dateRange.endDate),
          offset: this.offset,
          limit: this.limit,
          status: this.status,
          search: this.search,
          filter_products: this.product === '' ? [] : this.product,
        }
        const url = '/auth/api/v1/admin/ktp-verification'
        newAxiosIns.get(url, { params })
          .then(res => {
            const { data } = res.data
            data.forEach(item => {
            // eslint-disable-next-line no-param-reassign
              item.list_product = []

              iconProduct.forEach(type => {
                if (item[type.name]) {
                  item.list_product.push(iconProduct.find(p => p.name === type.name).src)
                }
              })
            })
            this.items.push(...data)
            this.offset += data.length
            this.loading = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(() => {
            this.loading = false
            this.$toast_error({ message: 'Gagal load data' })
          })
      }
    },
    searchData: _.debounce(function search(text) {
      this.getList()
    }, 1000),
  },
}
</script>
<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
